<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.LAST_LOGINS')">
          <template v-slot:body>
            <b-row class="table-filter">
              <b-col sm="4" class="my-2">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="my-2">
                <b-form-group :label="$t('LAST_LOGINS.START_DATE')">
                  <b-form-datepicker
                    v-model="searchForm.startDate"
                    class="mb-2"
                    :max="searchForm.endDate"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="my-2">
                <b-form-group :label="$t('LAST_LOGINS.END_DATE')">
                  <b-form-datepicker
                    v-model="searchForm.endDate"
                    class="mb-2"
                    :min="searchForm.startDate"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col class="my-sm-2">
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in userData"
                    v-bind:key="index"
                  >
                    <b-row>
                      <b-col md="4">
                        <b-form-select
                          v-model="item.label"
                          :options="typeData"
                          value-field="value"
                          text-field="text"
                          class="mr-sm-4"
                          @change="changeUserLabel(index)"
                        ></b-form-select>
                      </b-col>
                      <b-col md="4" class="mt-2 mt-md-0">
                        <b-form-select
                          v-if="item.label == 'serviceused'"
                          v-model="item.value"
                          :options="valueData"
                          value-field="value"
                          text-field="text"
                          class="mr-sm-4"
                        ></b-form-select>
                        <b-input
                          v-else
                          v-model="item.value"
                          id="inline-form-input-name"
                          :placeholder="$t('COMMON.VALUE')"
                        ></b-input>
                      </b-col>
                      <b-col
                        md="4"
                        class="d-flex justify-content-between align-items-center mt-2 mt-md-0"
                      >
                        <div>
                          <b-form-checkbox v-model="item.isnagte">{{
                            $t("LAST_LOGINS.NEGATE_CONDITION")
                          }}</b-form-checkbox>
                        </div>
                        <div>
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="cyan"
                            @click="addRow()"
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="pink"
                            @click="deleteRow(index)"
                          >
                            <v-icon dark>mdi-minus</v-icon>
                          </v-btn>
                        </div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col class="d-flex justify-content-end">
                <b-button variant="primary" class="mr-4" @click="searchData()">
                  {{ $t("COMMON.SEARCH") }}
                </b-button>
                <b-button variant="success" @click="resetSearch()">
                  {{ $t("LAST_LOGINS.RESET_SEARCH") }}
                </b-button>
              </b-col>
            </b-row>
            <v-data-table
              v-model="lastloginsTable.selected"
              :headers="headers"
              :items="lastloginsTable.lastlogins"
              :single-select="lastloginsTable.singleSelect"
              item-key="id"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.remote_ip="{ item }">
                <v-icon 
                  medium
                  color="error"
                  v-if="item.blocked_ip"
                  :title='$t("LAST_LOGINS.BLOCKED")'
                >
                  mdi-alert-octagon
                </v-icon>
                <a 
                  v-bind:href="'https://search.arin.net/rdap/?query=' + item.remote_ip" 
                  target="_blank"
                >{{ 
                    item.remote_ip 
                }}</a>
              </template>
              <template v-slot:item.action="{ item }">
                <b-button 
                  variant="success" 
                  @click="openMarkSafeModal(item, false)"
                  v-if="!item.description && !item.safe_ip"
                >
                  {{ $t("SECURITY.MARK_SAFE") }}
                </b-button>
                <v-icon
                  medium
                  color="dark"
                  v-b-tooltip.click
                  v-b-tooltip.hover
                  v-if="item.description"
                  :title="item.description"
                >
                  mdi-information-outline
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  @click="openMarkSafeModal(item, true)"
                  v-b-tooltip.click
                  v-b-tooltip.hover
                  :title='$t("COMMON.EDIT")'
                >
                  edit
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  @click="removeTrustedIP(item)"
                  v-if="item.description"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>

            <!-- ----------mark ip safe modal------------- -->
            <b-modal
              id="markSafe"
              ref="markSafe"
              :title="$t('SECURITY.MARK_SAFE') + ': ' + markSafeForm.remote_ip"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-form-group
                  v-if="markSafeForm.editable"
                  :description="$t('HOME.REMOTE_IP')"
                  :label="$t('HOME.REMOTE_IP')"
                  label-for="remoteIp"
                  label-cols-md="12"
                  :invalid-feedback='$t("VALIDATION.IP_INVALID")'
                  :state="validIp"
                  >
                    <b-form-input
                      id="remoteIp"
                      v-model="markSafeForm.remote_ip"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                  :description="$t('SECURITY.IP_DESC')"
                  :label="$t('COMMON.DESCRIPTION')"
                  label-for="ipDescription"
                  label-cols-md="12"
                  :invalid-feedback="invalidIpDescFeedback"
                  :state="validIpDesc"
                  >
                    <b-form-input
                      id="ipDescription"
                      v-model="markSafeForm.ipDescription"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                  :description="$t('SECURITY.TYPE_DESC')"
                  :label="$t('COMMON.RANGE')"
                  label-for="ipType"
                  label-cols-md="12"
                  >
                    <b-form-select
                      id="ipType"
                      v-model="markSafeForm.range"
                    >
                      <b-form-select-option value="32">IP: /32</b-form-select-option>
                      <b-form-select-option value="31">/31</b-form-select-option>
                      <b-form-select-option value="30">/30</b-form-select-option>
                      <b-form-select-option value="29">/29</b-form-select-option>
                      <b-form-select-option value="28">/28</b-form-select-option>
                      <b-form-select-option value="27">/27</b-form-select-option>
                      <b-form-select-option value="26">/26</b-form-select-option>
                      <b-form-select-option value="25">/25</b-form-select-option>
                      <b-form-select-option value="24">/24</b-form-select-option>
                      <b-form-select-option value="23">/23</b-form-select-option>
                      <b-form-select-option value="22">/22</b-form-select-option>
                      <b-form-select-option value="21">/21</b-form-select-option>
                      <b-form-select-option value="20">/20</b-form-select-option>
                      <b-form-select-option value="19">/19</b-form-select-option>
                      <b-form-select-option value="18">/18</b-form-select-option>
                      <b-form-select-option value="17">/17</b-form-select-option>
                      <b-form-select-option value="16">/16</b-form-select-option>
                      <b-form-select-option value="15">/15</b-form-select-option>
                      <b-form-select-option value="14">/14</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="confirmModalClose('markSafe')">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="markSafe()" :disabled="!validIpDesc">
                  {{ $t("SECURITY.MARK_SAFE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------end mark ip safe modal------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";

export default {
  name: "lastlogins",
  components: {
    KTPortlet,
  },
  data: () => ({
    lastloginsTable: {
      singleSelect: false,
      lastlogins: [],
      selected: [],
      itemPerPage: 5,
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    searchForm: {
      startDate: new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
    },
    markSafeForm: {
      ipDescription: "",
      range: 32,
      username: "",
      domain: "",
      remote_ip: "",
      editable: false,
    },
    userData: [{ label: "username", value: "", isnagte: 0 }],
  }),
  computed: {
    headers: function () {
      return [
        {
          text: this.$t("COMMON.USERNAME"),
          value: "username",
          sortable: false,
        },
        { text: this.$t("COMMON.DOMAIN"), value: "domain", sortable: false },
        {
          text: this.$t("HOME.REMOTE_IP"),
          value: "remote_ip",
          sortable: false,
        },
        {
          text: this.$t("LAST_LOGINS.LOCATION"),
          value: "location_country",
          sortable: false,
        },
        {
          text: this.$t("LAST_LOGINS.PASSWORD_USED"),
          value: "authlocation",
          sortable: false,
        },
        {
          text: this.$t("LAST_LOGINS.SERVICE_USED"),
          value: "authservice",
          sortable: false,
        },
        {
          text: this.$t("COMMON.DATE"),
          value: "login_timestamp",
          sortable: false,
        },
        { text: this.$t("COMMON.ACTION"), 
          value: "action", 
          sortable: false, 
        },
      ];
    },
    typeData: function () {
      return [
        { text: this.$t("COMMON.USERNAME"), value: "username" },
        { text: this.$t("HOME.REMOTE_IP"), value: "remote_ip" },
        { text: this.$t("LAST_LOGINS.SERVICE_USED"), value: "serviceused" },
      ];
    },
    valueData: function () {
      return [
        { text: this.$t("LAST_LOGINS.IMAP"), value: "imap" },
        { text: this.$t("LAST_LOGINS.POP3"), value: "pop3" },
        { text: this.$t("LAST_LOGINS.SMTP"), value: "smtp" },
        { text: this.$t("LAST_LOGINS.SIEVE"), value: "sieve" },
        { text: this.$t("LAST_LOGINS.LOGIN"), value: "login" },
      ];
    },
    validIpDesc() {
      let len = this.markSafeForm.ipDescription.length;
      let reg = new RegExp('^[0-9a-zA-Z_.-]+$');
      
      return (len > 1 && len <= 12 && reg.test(this.markSafeForm.ipDescription));
    },
    invalidIpDescFeedback() {
      let len = this.markSafeForm.ipDescription.length;
      if (len < 2) {
        return this.$t("VALIDATION.REQUIRED_MINLENGTH_FIELD", { num: "2" });
      }
      let reg = new RegExp('^[0-9a-zA-Z_.-]+$');
      if (!reg.test(this.markSafeForm.ipDescription)) {
        return this.$t("VALIDATION.REGEX", 
          {rule: this.$t("VALIDATION.LETTERS") + ", "+ this.$t("VALIDATION.NUMBERS") + ", _.-"});
      }
      return this.$t("VALIDATION.MAX_VALUE", { num: "12" });
    },
    validIp() {
      let reg = new RegExp('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
      
      return (reg.test(this.markSafeForm.remote_ip));
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
      includeAliasDomains: 1,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
      }
      if (this.$route.params.user) {
        let user = this.$route.params.user.split("@");
        this.selectDomain.selectedDomain.domain = user[1];
        this.userData = [{ label: "username", value: user[0], isnagte: 0 }];
        this.searchData();
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.LAST_LOGINS") },
    ]);
  },
  methods: {
    /*  -----modal close------- */
    confirmModalClose(modalRef) {
      this.$refs[modalRef].hide();
    },

    /* ----- dynamic row----- */
    addRow() {
      this.userData.push({
        label: "username",
        value: "",
        isnagte: 0,
      });
    },
    deleteRow(index) {
      if (this.userData.length != 1) this.userData.splice(index, 1);
    },
    searchData() {
      let userField = "";
      for (let i in this.userData) {
        let isnagteValue = 0;
        if (this.userData[i].value == "") {
          this.makeToastVariant(
            "danger",
            this.$t("VALIDATION.FILL_ALL_FIELDS")
          );
          return;
        }
        if (this.userData[i].isnagte == true) {
          isnagteValue = 1;
        }
        let eachUserField =
          "&field[]=" +
          this.userData[i].label +
          "&search[]=" +
          this.userData[i].value +
          "&negate[]=" +
          isnagteValue;

        userField += eachUserField;
      }

      let fields =
        "domain=" +
        this.selectDomain.selectedDomain.domain +
        "&loga=" +
        this.searchForm.startDate +
        "&logz=" +
        this.searchForm.endDate +
        userField;

      this.lastloginsTable.lastlogins = [];
      postRequest({
        action: "searchLastLogins",
        token: localStorage.id_token,
        fields: fields,
      }).then((res) => {
        if (res && res.returncode) {
          this.lastloginsTable.lastlogins = res.returndata;
        }
      });
    },
    resetSearch() {
      this.lastloginsTable.lastlogins = [];
      this.searchForm.startDate = new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10);
      this.searchForm.endDate = new Date().toISOString().slice(0, 10);
      this.userData = [{ label: "username", value: "", isnagte: 0 }];
      this.selectDomain.selectedDomain = this.selectDomain.allDomains[0];
    },
    changeUserLabel(index) {
      if (this.userData[index].label != "serviceused") {
        this.userData[index].value = "";
      } else {
        this.userData[index].value = "imap";
      }
    },
    openMarkSafeModal(item, edit) {
      this.markSafeForm.username = item.username;
      this.markSafeForm.domain = item.domain;
      this.markSafeForm.remote_ip = item.remote_ip;
      this.markSafeForm.ipDescription = "";
      this.markSafeForm.range = 32;
      this.markSafeForm.editable = edit ? true : false;
      this.$refs["markSafe"].show();
    },
    markSafe() {
      postRequest({
        action: "markIpSafeUnsafe",
        token: localStorage.id_token,
        username: this.markSafeForm.username,
        domain: this.markSafeForm.domain,
        remote_ip: this.markSafeForm.remote_ip,
        netmask: this.markSafeForm.range,
        description: this.markSafeForm.ipDescription,
      }).then((res) => {
        if (res && res.returncode) {
          this.confirmModalClose('markSafe');
          this.makeToastVariant("success", res.returndata);
          this.searchData();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    removeTrustedIP(item){
      if (item.safe_ip_id) {
        postRequest({
          action: "removeSafeUnsafeIPs",
          token: localStorage.id_token,
          id: item.safe_ip_id,
        }).then((res) => {
          if (res && res.returncode) {
            this.makeToastVariant("success", res.returndata);
            this.searchData();
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      } else {
        this.makeToastVariant("danger", "Error deleting");
      }
    },
    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
